@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Rubik", sans-serif;
  color: #153750;
  @apply lg:overflow-hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* MATERIAL ICONS */

.material-icons-outlined,
.material-icons {
  line-height: unset !important;
  /* font-size: unset !important; */
}

.material-icons.md-3xl,
.material-icons-outlined.md-3xl {
  @apply text-3xl;
}

.material-icons.md-2xl,
.material-icons-outlined.md-2xl {
  @apply text-2xl;
}

.material-icons.md-block,
.material-icons-outlined.md-block {
  @apply block;
}

/* ACCORDION */

[data-reach-accordion-item] {
  @apply p-2 rounded;
}

.px-0[data-reach-accordion-item] {
  padding-left: 0;
  padding-right: 0;
}

.accordion-blue [data-reach-accordion-item][data-state="open"] {
  @apply bg-blue bg-opacity-10;
}

.accordion-green-item > [data-reach-accordion-panel][data-state="open"] {
  @apply bg-green bg-opacity-20;
}

[data-reach-accordion-panel][data-state="open"] {
  @apply outline-none;
}

[data-reach-accordion-button],
[data-reach-accordion-button]:focus {
  @apply outline-none;
}

.accordion-green-item > [data-reach-accordion-button][aria-expanded="true"] {
  @apply bg-green;
}

[data-reach-accordion-button][aria-expanded="true"] .md-expand {
  @apply transform rotate-180;
}
