.access-code-container {
  background-image: url("../../Assets/login-bg.png");
}

.access-code {
  width: 100%;
}

@media (min-width: 1024px) {
  .access-code {
    width: 500px;
  }
}
